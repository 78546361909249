/* CSS */
  import "@css/tailwind.pcss";
  // custom plugins
  import "plyr/dist/plyr.css";
  // app alwyas last as it's custom
  import "@css/app.pcss";

/* JS */
  import 'lazysizes';

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect'
 
Alpine.plugin(focus);
Alpine.plugin(intersect);

window.Alpine = Alpine;
Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}


import { animate, inView, stagger } from "motion";

inView(".animation_titleContainer", ({ target }) => {
     animate(
          target.querySelectorAll('.animation_titleElement'),
          { 
               opacity: [0,1],
          },
          {
               delay: stagger(0.5),
               duration: 0.8,
               easing: [0.17, 0.55, 0.55, 1]
          }
     );
   });
inView(".animation_contentContainer", ({ target }) => {
     animate(
          target.querySelectorAll('.animation_contentElement'),
          { 
               opacity: [0,1],
               transform: ["translateY(5rem)", "translateY(0)"]
          },
          {
               delay: stagger(0.2),
               duration: 0.6,
               easing: [0.17, 0.55, 0.55, 1]
          }
     );
     animate(
          target.querySelectorAll('.animation_contentElementFadeOnly'),
          { 
               opacity: [0,1],
          },
          {
               delay: stagger(0.5, { start: .8 }),
               duration: 1,
               easing: [0.17, 0.55, 0.55, 1]
          }
     );
   });
   inView(".animation_contentSecondaryContainer", ({ target }) => {
     animate(
          target.querySelectorAll('.animation_contentSecondaryElement'),
          { 
               opacity: [0,1],
               transform: ["translateY(2rem)", "translateY(0)"]
          },
          {
               delay: stagger(0.3, { start: 1 }),
               duration: 0.3,
               easing: [0.17, 0.55, 0.55, 1]
          }
     );
   });

// custom animations
inView(".hero_image_grid_with_gradient--imagesContainer", ({ target }) => {
     animate(
          target.querySelectorAll('.hero_image_grid_with_gradient--imagesElementEven'),
          { 
               opacity: [0,1],
               transform: ["translateX(-4rem)", "translateX(0)"]
          },
          {
               delay: stagger(0.6),
               duration: 0.9,
               easing: [0.17, 0.55, 0.55, 1]
          }
     );
     animate(
          target.querySelectorAll('.hero_image_grid_with_gradient--imagesElementOdd'),
          { 
               opacity: [0,1],
               transform: ["translateX(4rem)", "translateX(0)"]
          },
          {
               delay: stagger(0.6),
               duration: 0.9,
               easing: [0.17, 0.55, 0.55, 1]
          }
     );
   });

   inView(".animation_flipContainer", ({ target }) => {
     animate(
          target.querySelectorAll('.animation_flipElement'),
          { 
               opacity: [0,1],
               // rotate: 90
          },
          { 
               delay: stagger(.5, { start: 1 }),
               // duration: 0,
               // direction: "alternate-reverse",
               // repeat: 5,
               duration: .1,
               // repeat: 6,
               // direction: "alternate"
          }
     );

          // It's taking the 1 image and going :: show/hide x[Repeat#)] :: then onto the next image to do the same
          // I want: show/hide all 3 in a row, then hide all, then show/hide all 3 in a row X [#]


     // animate(
     //      target.querySelectorAll('.animation_flipElement'),
     //      { 
     //           opacity: [0,1],
     //      }, 
     //      { 
     //           delay: stagger(0.3, { start: 1 }),
     //           duration: 0,
     //           repeat: 4 
     //      }
     // );
   });
/**
 * Animations
 */
//  const observer = new IntersectionObserver((entries) => { 
//      entries.forEach((entry) => { 
//           if (entry.isIntersecting) { 
//                entry.target.classList.add('show');
//           } else { 
//                entry.target.classList.remove('show');
//           }
//      });
// });

// const hiddenElements = document.querySelectorAll('.animate');
// hiddenElements.forEach((el) => observer.observe(el));